import { Grid, Tooltip } from "@mui/material";
import { useTranslation } from "react-i18next";
import useStyles from "../../theme/styles";
import "./style.scss";

const COLOR_SCALE_RADIATION = [
  "rgb(59, 27, 0)",
  "rgb(93, 42, 0)",
  "rgb(171, 86, 3)",
  "rgb(198, 105, 5)",
  "rgb(237, 165, 33)",
  "rgb(250, 198, 53)",
  "rgb(255, 230, 70)",
];

const COLOR_SCALE_ASPECT = [
  "rgb(141, 90, 153)",
  "rgb(250, 232, 35)",
  "rgb(251, 22, 12)",
  "rgb(60, 234, 17)",
  "rgb(141, 90, 153)",
];
const VALUES_SCALE_ASPECT = [45, 135, 225, 315, 360];

const COLOR_SCALE_SLOPE = [
  "rgb(26, 150, 65)",
  "rgb(166, 217, 106)",
  "rgb(230, 255, 192)",
  "rgb(253, 174, 97)",
  "rgb(215, 25, 28)",
];
const VALUES_SCALE_SLOPE = [5, 15, 25, 50, 86];

const COLOR_SCALE_NDVI = [
  "rgb(251, 22, 12)",
  "rgb(239, 69, 17)",
  "rgb(239, 171, 25)",
  "rgb(26, 150, 65)",
  "rgb(21, 225, 72)",
  "rgb(124, 237, 96)",
];
const VALUES_SCALE_NDVI = [0.3, 0.5, 0.6, 0.7, 0.8, 1];

const COLOR_SCALE_ALTITUDE = [
  "rgb(0, 102, 51)",
  "rgb(0, 153, 51)",
  "rgb(0, 204, 102)",
  "rgb(153, 255, 102)",
  "rgb(255, 255, 102)",
  "rgb(255, 204, 102)",
  "rgb(255, 153, 51)",
  "rgb(204, 102, 0)",
  "rgb(153, 102, 51)",
  "rgb(102, 51, 0)",
];

const VALUES_SCALE_VEGETATION_HEALTH = [
  "-1 - -0.01",
  "-0.01 - 0.17",
  "0.17 - 0.33",
  "0.33 - 0.49",
  "0.49 - 0.66",
  "0.66 - 0.83",
  "0.83 - 1",
];
const COLOR_SCALE_VEGETATION_HEALTH = [
  "rgb(215, 25, 28)",
  "rgb(240, 124, 74)",
  "rgb(254, 201, 129)",
  "rgb(196, 230, 135)",
  "rgb(119, 195, 92)",
  "rgb(91, 174, 61)",
  "rgb(26, 150, 65)",
];

const VALUES_SCALE_HUMIDITY = ["-1.0 - -0.5", "-0.5 - -0.2", "-0.2 - 0", "0 - 0.15", "0.15 - 0.3", "0.3 - 0.5", "0.5 - 1"];

const COLOR_SCALE_HUMIDITY = [
  "rgb(35,145,36)",
  "rgb(154,204,151)",
  "rgb(210, 252, 205)",
  "rgb(213, 242, 255)",
  "rgb(32, 144, 251)",
  "rgb(11, 51, 236)",
  "rgb(5, 25, 135)",
];

const VALUES_SCALE_WATER_BODIES = ["-1.0 - -0.3", "-0.3 - 0", "0 - 0.2", "0.2 - 0.5", "0.5 - 1.0"];

const COLOR_SCALE_WATER_BODIES = [
  "rgb(245, 105, 24)",
  "rgb(231, 215, 57)",
  "rgb(42, 185, 238)",
  "rgb(66, 73, 177)",
  "rgb(48, 18, 59)",
];

const VALUES_SCALE_NIGHT_TIME_LIGHTS = ["5", "10", "20", "30", "40", "50", "70", "75"]

const COLOR_SCALE_NIGHT_TIME_LIGHTS = [
  "rgb(0, 0, 0)",
  "rgb(36, 31, 0)",
  "rgb(73, 62, 0)",
  "rgb(109, 93, 0)",
  "rgb(146, 124, 0)",
  "rgb(182, 155, 0)",
  "rgb(219, 186, 0)",
  "rgb(255, 213, 0)",
];

const StyleCard = ({ type, radiationMinMaxValue, altitudeMinMaxValue, checked }) => {
  const classes = useStyles();
  const { t } = useTranslation()

  const formatValue = (value) => {
    const v = parseInt(value).toString();
    const formated = v.replace(".", ",").replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    return formated;
  };

  const VALUES_SCALE_RADIATION = Array.from({ length: 7 }, (_value, index) => index).map((index) => {
    return (((radiationMinMaxValue[1] - radiationMinMaxValue[0]) / 6) * index + radiationMinMaxValue[0]).toFixed(1)
  })

  const VALUES_SCALE_ALTITUDE = Array.from({ length: 10 }, (_value, index) => index).map((index) => {
    return (((altitudeMinMaxValue[1] - altitudeMinMaxValue[0]) / 9) * index + altitudeMinMaxValue[0]).toFixed(2)
  })
  const renderLegend = (colorValues, values) => {
    return (
      <div className="layout" style={{ display: "table", width: "200px", height: "10px" }}>
        {colorValues.map((element, index) => {
          return (
            <Tooltip title={`${values[index]}`} placement="top-end">
              <div className={classes.legend} style={{ backgroundColor: element }}></div>
            </Tooltip>
          )
        })}
      </div>
    )
  }

  const renderLegendInfo = (checked, values) => {
    return checked ?
      <Grid container sx={{ m: 0, alignItems: "center" }}>
        <Grid item xs={6}>
          <p style={{ marginTop: 2, marginBottom: 2, fontSize: "12px", }}>{values[0]}</p>
        </Grid>
        <Grid container item xs={6} sx={{ justifyContent: "end" }}>
          <p style={{ marginTop: 2, marginBottom: 2, fontSize: "12px" }}>{values[values.length - 1]}</p>
        </Grid>
      </Grid> : null
  }

  return (
    <>
      {type === "Radiation" && (
        <div>
          <p style={{ fontSize: "12px", margin: "0px" }}>{t('common.radiationUnits')}</p>
          {renderLegend(COLOR_SCALE_RADIATION, VALUES_SCALE_RADIATION)}
          {renderLegendInfo(checked, VALUES_SCALE_RADIATION)}
        </div>
      )}
      {type === "Aspect" && (
        <div>
          <p style={{ fontSize: "12px", margin: "0px" }}>{t('common.degrees', { degreeRange: '(0-360º)' })}</p>
          {renderLegend(COLOR_SCALE_ASPECT, VALUES_SCALE_ASPECT)}
          {renderLegendInfo(checked, VALUES_SCALE_ASPECT)}
        </div>
      )}
      {(type === "Slope" || type === "Slope (Pitch)") && (
        <div>
          <p style={{ fontSize: "12px", margin: "0px" }}>{t('common.degrees', { degreeRange: '(0-90º)' })}</p>
          {renderLegend(COLOR_SCALE_SLOPE, VALUES_SCALE_SLOPE)}
          {renderLegendInfo(checked, VALUES_SCALE_SLOPE)}
        </div>
      )}
      {type === "NDVI" && (
        <div>
          {renderLegend(COLOR_SCALE_NDVI, VALUES_SCALE_NDVI)}
          {renderLegendInfo(checked, VALUES_SCALE_NDVI)}
        </div>
      )}
      {(type === "Altitude" || type === "Height" || type === "Vegetation height") && (
        <div>
          <p style={{ fontSize: "12px", margin: "0px" }}>{t('common.metersAvobeSeaLevel')}</p>
          {renderLegend(COLOR_SCALE_ALTITUDE, VALUES_SCALE_ALTITUDE)}
          {renderLegendInfo(checked, VALUES_SCALE_ALTITUDE)}
        </div>
      )}
      {type === "Vegetation health" && (
        <div>
          <p style={{ fontSize: "12px", margin: "0px" }}>{t('common.status')}</p>
          {renderLegend(COLOR_SCALE_VEGETATION_HEALTH, VALUES_SCALE_VEGETATION_HEALTH)}
          {renderLegendInfo(checked, VALUES_SCALE_VEGETATION_HEALTH)}
        </div>
      )}
      {(type === "Watersheds" || type === "Burned areas") && (
        <div className="layout" style={{ display: "table", width: "50px", height: "10px" }}>
          <div
            className={classes.legend}
            style={{ backgroundColor: "rgb(112, 39, 209)", border: "2px solid rgb(112, 39, 209)" }}
          ></div>
        </div>
      )}
      {type === "Solar Panels" && (
        <div className="layout" style={{ display: "table", width: "50px", height: "10px" }}>
          <div
            className={classes.legend}
            style={{ backgroundColor: "rgb(112, 39, 209)", border: "2px solid rgb(112, 39, 209)" }}
          ></div>
        </div>
      )}
      {(type === "Solar Trackers Plants" ||
        type === "Solar Panels Plants" ||
        type === "Roads (Solar Plants)" ||
        type === "Powerblocks") && (
          <div className="layout" style={{ display: "table", width: "50px", height: "10px" }}>
            <div
              className={classes.legend}
              style={{ backgroundColor: "rgb(112, 39, 209)", border: "3px solid rgb(112, 39, 209)" }}
            ></div>
          </div>
        )}
      {type === "Wind Turbines" && (
        <div className="layout" style={{ display: "table" }}>
          <div
            className={classes.legend}
            style={{
              backgroundColor: "rgb(112, 39, 209)",
              border: "2px solid rgb(112, 39, 209)",
              borderRadius: "15px",
              width: "15px",
              height: "15px",
            }}
          ></div>
        </div>
      )}
      {type === "Humidity" && (
        <div>
          <p style={{ fontSize: "12px", margin: "0px" }}>{t('common.status')}</p>
          {renderLegend(COLOR_SCALE_HUMIDITY, VALUES_SCALE_HUMIDITY)}
          {renderLegendInfo(checked, VALUES_SCALE_HUMIDITY)}
        </div>
      )}
      {type === "Water bodies" && (
        <div>
          <p style={{ fontSize: "12px", margin: "0px" }}>{t('common.status')}</p>
          {renderLegend(COLOR_SCALE_WATER_BODIES, VALUES_SCALE_WATER_BODIES)}
          {renderLegendInfo(checked, VALUES_SCALE_WATER_BODIES)}
        </div>
      )}
      {type === "Night time lights" && (
        <div>
          <p style={{ fontSize: "12px", margin: "0px" }}>{t('common.status')}</p>
          {renderLegend(COLOR_SCALE_NIGHT_TIME_LIGHTS, VALUES_SCALE_NIGHT_TIME_LIGHTS)}
          {renderLegendInfo(checked, VALUES_SCALE_NIGHT_TIME_LIGHTS)}
        </div>
      )}
    </>
  );
};

export default StyleCard;
