// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container-btn-map-measure {
  bottom: 0px;
  position: absolute;
  margin-left: 22px;
  margin-bottom: 22px;
  background-color: white;
  padding: 7px;
  font-size: 12px;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  border-radius: 2px;
}

.container-btn-map-zoom {
  bottom: 80px;
  position: absolute;
  right: 51px;
  cursor: pointer;
}

.container-btn-map-end {
  display: flex;
  justify-content: flex-end;
}

.container-btn-map {
  top: 0px;
  position: absolute;
  left: 0px;
  margin-left: 20px;
}

.draw_cursor {
  cursor: crosshair, auto;
}`, "",{"version":3,"sources":["webpack://./src/components/mapNewProject/style.scss"],"names":[],"mappings":"AAAA;EACI,WAAA;EACA,kBAAA;EACA,iBAAA;EACA,mBAAA;EACA,uBAAA;EACA,YAAA;EACA,eAAA;EACA,yHAAA;EACA,kBAAA;AACJ;;AAEA;EACI,YAAA;EACA,kBAAA;EACA,WAAA;EACA,eAAA;AACJ;;AAEA;EACI,aAAA;EACA,yBAAA;AACJ;;AAEA;EACI,QAAA;EACA,kBAAA;EACA,SAAA;EACA,iBAAA;AACJ;;AAEA;EACI,uBAAA;AACJ","sourcesContent":[".container-btn-map-measure{\n    bottom: 0px;\n    position: absolute;\n    margin-left: 22px;\n    margin-bottom: 22px;\n    background-color: white;\n    padding: 7px;\n    font-size: 12px;\n    box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);\n    border-radius: 2px;\n}\n\n.container-btn-map-zoom {\n    bottom: 80px;\n    position: absolute;\n    right: 51px;\n    cursor: pointer;\n  }\n\n.container-btn-map-end {\n    display: flex;\n    justify-content: flex-end;\n}\n\n.container-btn-map {\n    top: 0px;\n    position: absolute;\n    left: 0px;\n    margin-left: 20px;\n}\n\n.draw_cursor {\n    cursor: crosshair, auto;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
