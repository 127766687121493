// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.body_container {
  margin: 100px 122px;
}
.body_container .container_button {
  display: flex;
  justify-content: flex-end;
}

.logo {
  cursor: pointer;
  padding-left: 60px;
}

.accordionItem {
  margin-bottom: 24px;
  height: -moz-fit-content;
  height: fit-content;
}

.container_newP {
  display: flex;
  justify-content: flex-end;
}

.container_details {
  display: flex;
  flex-direction: column;
}

.text_sub {
  border-bottom: 1px solid #7024d1;
}

.containerStats {
  width: 90%;
  margin: 30px;
}

.container_accordionTitle {
  width: 100%;
}

.tabWidth {
  width: 20%;
}
.tabWidth.Mui-selected {
  color: #7024d1 !important;
}

.containerLoader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
}

.contentPaginationMap {
  display: flex;
  justify-content: center;
  position: fixed;
  background-color: white;
  width: 100%;
  text-align: center;
  bottom: 18px;
  padding-bottom: 10px;
}`, "",{"version":3,"sources":["webpack://./src/pages/projects/style.scss"],"names":[],"mappings":"AAAA;EACE,mBAAA;AACF;AACE;EACE,aAAA;EACA,yBAAA;AACJ;;AAGA;EACE,eAAA;EACA,kBAAA;AAAF;;AAGA;EACE,mBAAA;EACA,wBAAA;EAAA,mBAAA;AAAF;;AAIA;EACE,aAAA;EACA,yBAAA;AADF;;AAIA;EACE,aAAA;EACA,sBAAA;AADF;;AAIA;EACE,gCAAA;AADF;;AAIA;EACE,UAAA;EACA,YAAA;AADF;;AAIA;EACE,WAAA;AADF;;AAIA;EACE,UAAA;AADF;AAGE;EACE,yBAAA;AADJ;;AAKA;EACE,kBAAA;EACA,QAAA;EACA,SAAA;EACA,gCAAA;EACA,aAAA;EACA,uBAAA;AAFF;;AAKA;EACE,aAAA;EACA,uBAAA;EACA,eAAA;EACA,uBAAA;EACA,WAAA;EACA,kBAAA;EACA,YAAA;EACA,oBAAA;AAFF","sourcesContent":[".body_container {\n  margin: 100px 122px;\n\n  .container_button {\n    display: flex;\n    justify-content: flex-end;\n  }\n}\n\n.logo {\n  cursor: pointer;\n  padding-left: 60px;\n}\n\n.accordionItem {\n  margin-bottom: 24px;\n  height: fit-content;\n}\n\n\n.container_newP {\n  display: flex;\n  justify-content: flex-end;\n}\n\n.container_details {\n  display: flex;\n  flex-direction: column;\n}\n\n.text_sub {\n  border-bottom: 1px solid #7024d1;\n}\n\n.containerStats {\n  width: 90%;\n  margin: 30px;\n}\n\n.container_accordionTitle {\n  width: 100%;\n}\n\n.tabWidth {\n  width: 20%;\n\n  &.Mui-selected {\n    color: #7024d1 !important;\n  }\n}\n\n.containerLoader{\n  position: absolute;\n  top: 50%;\n  left: 50%;\n  transform: translate(-50%, -50%);\n  display: flex;\n  justify-content: center;\n}\n\n.contentPaginationMap{\n  display: flex;\n  justify-content: center;\n  position: fixed;\n  background-color: white;\n  width: 100%;\n  text-align: center;\n  bottom: 18px;\n  padding-bottom: 10px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
